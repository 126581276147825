import SearchSvg from "@/_assets/svg/SearchSvg";
import { FC } from "react";
import s from "./InputSearch.module.scss";
type TProps = {
	onChangeText: (params: string) => void;
	value: string;
	isShowIcon?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;
const InputSearch: FC<TProps> = (props) => {
	const { value, onChangeText, isShowIcon = true, ...original } = props;

	return (
		<div className={s.wrapInputSearch}>
			{isShowIcon && <SearchSvg />}
			<input
				{...original}
				type="text"
				value={value}
				onChange={(e) => onChangeText(e.target.value)}
			/>
		</div>
	);
};
export default InputSearch;
