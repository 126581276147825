import { create } from "zustand";
import { TSeoState } from "./seo.type";
export const INIT_SEO_DATA = {
	ru: {
		link: "",
		metaTitle: "",
		metaDescription: "",
		title: "",
		description: "",
		tags: [],
	},
	en: {
		link: "",
		metaTitle: "",
		metaDescription: "",
		title: "",
		description: "",
		tags: [],
	},
	color: "",
	fileURL: "",
};
export const useSeoStore = create<TSeoState>((set) => ({
	seoData: {
		ru: {
			link: "",
			metaTitle: "",
			metaDescription: "",
			title: "",
			description: "",
			tags: [],
		},
		en: {
			link: "",

			metaTitle: "",
			metaDescription: "",
			title: "",
			description: "",
			tags: [],
		},
		color: "",
		fileURL: "",
	},
	setSeoData: ({ field, value, lang = null }) =>
		set((state) => {
			if (!lang) {
				console.log(value);
				return {
					seoData: {
						...state.seoData,
						[field]: value,
					},
				};
			}
			return {
				seoData: {
					...state.seoData,
					[lang]: {
						...state.seoData[lang],
						[field]: value,
					},
				},
			};
		}),
	setTags: ({ field, tag, lang }) =>
		set((state) => {
			const findIndex = state.seoData[lang].tags.findIndex(
				(e) => e.id === tag.id
			);
			const tags = [...state.seoData[lang].tags];
			if (findIndex > -1) {
				return {
					seoData: {
						...state.seoData,
						[lang]: {
							...state.seoData[lang],
							tags: [...tags.slice(0, findIndex), ...tags.slice(findIndex + 1)],
						},
					},
				};
			} else {
				return {
					seoData: {
						...state.seoData,
						[lang]: {
							...state.seoData[lang],
							tags: [...tags, { id: tag.id, title: tag.title }],
						},
					},
				};
			}
		}),
}));
