import { useLanguage } from "@/libs/context/LanguageProvider";
import { FC, useEffect, useState } from "react";
import { TTabItem } from "../../../../components/Tabs/type";
import EmbeddedContent from "../../_common/AddNewResource/EmbeddedContent/EmbeddedContent";
import ReceivingData from "../../_common/AddNewResource/ReceivingData/ReceivingData";
import SettingWidget from "../../_common/AddNewResource/SettingWidget/SettingWidget";
import s from "./WrapperAddNewArticle.module.scss";

import Tabs from "@/components/Tabs/Tabs";
import WrapperAddNewResource from "../../_common/_comp/WrapperAddNewResource";

import useTabs from "@/libs/hooks/use-tabs";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import useArticleStore from "@/store/newResourceStore/article";
import { TArticleKeysTabs } from "@/store/newResourceStore/article/articleStore.type";

import { HeaderActions } from "../../_common/_comp";
import { useManipulationData } from "../../_common/_hooks";
import { TEditingData } from "../../_common/_hooks/use-manipulation-data/type";
import { TVariantOpenPage } from "../../Constructor.type";
import SeoResource from "./SeoResource/SeoResource";
import {
	TArticleCreateReq,
	TArticleUpdateReq,
	TSingleArticleRes,
} from "@/services/API/articles/articles.type";
import {
	useCreateArticle,
	useDeleteArticleById,
	useGetArticleAllLangById,
	useUpdateArticleById,
} from "@/services/API/articles/hook";
import { Loader } from "@/libs/UI/Jammer";
import { VariantNewResourceContext } from "../../_common/AddNewResource/ReceivingData/_context/VariantNewResourceContext";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";

const INIT_TABS = [
	{
		key: "seo",
		label: "SEO",
		isActive: true,
	},
	{
		key: "content",
		label: "Контент",
		isActive: false,
	},
] as TTabItem<TArticleKeysTabs>[];
const WrapperAddNewArticle: FC<TVariantOpenPage> = ({
	editFor,
	labelPage,
	id = -1,
}) => {
	const { getLocalization } = useLanguage();
	const { activeTab, changeTabs, tabs } = useTabs<TArticleKeysTabs>(INIT_TABS);
	const { articleObj, bindTransformData, bindActionData, setting } =
		useArticleStore();
	const { bindStore } = useConstructorStore();
	const settings = useSettingStore();
	/////////

	const [editingData, setEditingData] = useState<
		TEditingData<TSingleArticleRes>
	>({
		ru: null,
		en: null,
	});

	const { mutateAsync: createItem } = useCreateArticle();
	const { mutateAsync: deleteItem } = useDeleteArticleById();
	const { mutateAsync: getAllLangItem } = useGetArticleAllLangById();
	const { mutateAsync: updateItem } = useUpdateArticleById();

	/////////

	const { handleSentData, handleCopyStore, handleLocalRemove } =
		useManipulationData({
			bindActionData: {
				loadLocalData: bindActionData.loadArticleData,
				removeLocalData: bindActionData.removeArticleData,
				updateLocalData: bindActionData.updateArticleData,
			},
			editingData,
			variantResource: "articles",
			copyArray: bindStore.articles[settings.getCurrentLang("articles")],
			bindTransformData,
			editFor,
		});

	const fetchSentData = async () => {
		const sentSetting = handleSentData();
		if (sentSetting !== null) {
			if (editFor === "ADD") {
				await createItem(sentSetting.sentData as TArticleCreateReq).then(() => {
					sentSetting.clear();
				});
			}
			if (editFor === "UPDATE") {
				await updateItem({
					id,
					body: sentSetting.sentData as TArticleUpdateReq,
				}).then(() => {
					sentSetting.clear();
				});
			}
		}
	};

	const fetchDeleteNews = async () => {
		const _id = id || articleObj?.id || -1;
		await deleteItem({
			lang: settings.getCurrentLang("articles"),
			id: _id,
		}).then(() => {
			console.log("success remove article");
			handleLocalRemove();
		});
	};

	/////////

	useEffect(() => {
		if (id > -1) {
			getAllLangItem({ id }).then((e) => {
				setEditingData({
					en: e.dataEn,
					ru: e.dataRu,
				});
			});
		}
	}, [id]);

	if (editFor === "UPDATE" && !editingData.ru && !editingData.en)
		return (
			<Loader
				params={{
					visible: !editingData.ru && !editingData.en,
				}}
			/>
		);
	return (
		<VariantNewResourceContext.Provider value={{ variantResource: "articles" }}>
			<WrapperAddNewResource
				title={getLocalization(labelPage)}
				goBack={true}
				rightComp={
					<HeaderActions
						disabled={false}
						handleUpdateStore={bindActionData.updateArticleData}
						handleCopyStore={handleCopyStore}
					/>
				}
			>
				<div className={s.wrap}>
					<div className={s.main_block}>
						<Tabs
							data={tabs}
							callback={changeTabs}
							saveData={bindActionData.updateArticleData}
						/>
						{activeTab.key === "seo" && <SeoResource />}
						{activeTab.key === "content" && <ReceivingData />}
					</div>
					<div className={s.widgets_block}>
						{activeTab.key === "content" && <EmbeddedContent />}
						<SettingWidget
							handleRemove={fetchDeleteNews}
							handleSent={fetchSentData}
							editFor={editFor}
						/>
					</div>
				</div>
			</WrapperAddNewResource>
		</VariantNewResourceContext.Provider>
	);
};
export default WrapperAddNewArticle;
