import { create } from "zustand";
import { THistoryData, TSeoState } from "./seo.type";
export const INIT_HISTORY_DATA = {
	promocode: "",
	link: "",
	color: "",
	preview_image: "",
	ru: {
		image: "",
		name: "",
		text: "",
	},
	en: {
		image: "",
		name: "",
		text: "",
	},
} as THistoryData;

export const useSeoStore = create<TSeoState>((set) => ({
	seoData: INIT_HISTORY_DATA,
	setSeoData: ({ field, value, lang = null }) =>
		set((state) => {
			if (!lang) {
				return {
					seoData: {
						...state.seoData,
						[field]: value,
					},
				};
			}
			return {
				seoData: {
					...state.seoData,
					[lang]: {
						...state.seoData[lang],
						[field]: value,
					},
				},
			};
		}),
}));
