import { toast } from "react-toastify";

type TParams = {
	ru: boolean;
	en: boolean;
};
export const toastGetAllLang = ({ ru, en }: TParams) => {
	if (ru) {
		toast.success("Получена ru версия");
	}
	if (en) {
		toast.success("Получена en версия");
	}
	if (!ru) {
		toast.warning("Ошибка получения ru");
	}
	if (!en) {
		toast.warning("Ошибка получения en");
	}
};
