import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
	INIT_CONSTRUCTOR_DATA,
	useConstructorStore,
} from "../_common/constructor/constructorStore";
import {
	TArticleDataStore,
	TArticleStore,
	TArticleStorePersist,
} from "./articleStore.type";
import { INIT_SEO_DATA, useSeoStore } from "./seo/seo";
import {
	INIT_SETTING_DATA,
	useSettingStore,
} from "../_common/setting/settingStore";
import {
	TArticleCreateReq,
	TArticleUpdateReq,
} from "@/services/API/articles/articles.type";

export const useArticleStore = create<
	TArticleStore,
	[["zustand/persist", TArticleStorePersist]]
>(
	persist(
		(set, get) => ({
			articleObj: {
				id: -1,
				seoStore: useSeoStore.getState().seoData,
				contentStore: useConstructorStore.getState().bindStore.articles,
			},
			setting: useSettingStore.getState().settingObj.articles,

			bindActionData: {
				updateArticleData: () => {
					set((state) => ({
						...state,
						articleObj: {
							...state.articleObj,
							contentStore: useConstructorStore.getState().bindStore.articles,
							seoStore: useSeoStore.getState().seoData,
						},
						setting: useSettingStore.getState().settingObj.articles,
					}));
				},
				loadArticleData: () => {
					const storedData = get();
					if (storedData?.articleObj && storedData?.setting) {
						useConstructorStore.setState((state) => ({
							...state,
							bindStore: {
								...INIT_CONSTRUCTOR_DATA,
								news: storedData.articleObj.contentStore,
							},
						}));
						useSeoStore.setState((state) => ({
							...state,
							seoData: {
								...INIT_SEO_DATA,
								...storedData.articleObj.seoStore,
							},
						}));
						useSettingStore.setState((state) => ({
							settingObj: {
								...state.settingObj,
								articles: {
									...INIT_SETTING_DATA,
									...storedData.setting,
								},
							},
						}));
						set((state) => ({
							...state,
							articleObj: storedData.articleObj,
							setting: storedData.setting,
						}));
					}
				},
				removeArticleData: () => {
					set((state) => ({
						articleObj: {
							contentStore: INIT_CONSTRUCTOR_DATA.articles,
							seoStore: INIT_SEO_DATA,
						},
						setting: INIT_SETTING_DATA,
					}));
					localStorage.removeItem("article-storage");
				},
			},
			bindTransformData: {
				getDataForRequest: ({ lang, variantRequest = "ADD" }) => {
					const storedData = get().articleObj;
					let sent_object: TArticleCreateReq;
					let update_object: TArticleUpdateReq;
					sent_object = {
						article: {
							publish_at: String(new Date().toISOString()),
							title: storedData.seoStore[lang].title,
							tags: storedData.seoStore[lang].tags,
							banner: storedData.seoStore.fileURL,
							color: storedData.seoStore.color,
							link: storedData.seoStore[lang].link,
							btn_url: storedData.seoStore.btn_url,
							description: storedData.seoStore[lang].description,
							article_content: storedData.contentStore[lang],
						},
						language: {
							lang: lang,
						},
					};
					if (variantRequest === "UPDATE") {
						update_object = {
							...sent_object,
							article: {
								...sent_object.article,
								id: get().articleObj.id || -1,
								hidden: get().setting.isHidden,
							},
						};
						return update_object;
					}

					return sent_object;
				},
				setDataFromRequest: ({ dataRu, dataEn }) => {
					let storedData = get().articleObj;

					storedData = {
						seoStore: {
							btn_url: dataRu?.data.btn_url || "",
							fileURL: dataRu?.data.banner || "",
							color: dataRu?.data.color || "",
							en: {
								title: dataEn?.data.title || "",
								metaTitle: "",
								metaDescription: "",
								tags: dataEn?.data.tags || INIT_SEO_DATA.en.tags,
								description: dataEn?.data.description || "",
								link: dataEn?.data.link || "",
							},
							ru: {
								title: dataRu?.data.title || "",
								metaTitle: "",
								metaDescription: "",
								tags: dataRu?.data.tags || INIT_SEO_DATA.ru.tags,
								description: dataRu?.data.description || "",
								link: dataRu?.data.link || "",
							},
						},
						contentStore: {
							ru:
								dataRu?.data.article_content ||
								INIT_CONSTRUCTOR_DATA.articles.ru,
							en:
								dataEn?.data.article_content ||
								INIT_CONSTRUCTOR_DATA.articles.ru,
						},
					} as TArticleDataStore;

					set((state) => ({
						...state,
						articleObj: {
							...storedData,
							id: dataRu?.data.id || dataEn?.data.id || -1,
						},
						setting: {
							...get().setting,
							isHidden:
								dataRu?.data.hidden !== undefined ? dataRu.data.hidden : true,
						},
					}));
					get().bindActionData.loadArticleData();
					return storedData;
				},
			},
		}),
		{
			name: "article-storage",
			storage: createJSONStorage(() => localStorage),
			partialize: (state) => {
				return { articleObj: state.articleObj, setting: state.setting };
			},
		}
	)
);
