const BASE_PROD = "https://lk.casino-track.com/-api-";
const BASE_LEVA_URL = "https://lk.leva-ndar.ru/-api-";

const BASE_URL = BASE_PROD;

const PREFIX = {
	current_prefix: "/v1/admin",
	auth: "/v1/auth",
};
const SERVICES = {
	casino: `${PREFIX.current_prefix}/casino`,
	news: `${PREFIX.current_prefix}/news`,
	history: `${PREFIX.current_prefix}/story`,
	image_cdn: `${PREFIX.current_prefix}/file/image`,
	image_cdn_public: `/v1/file/image`,
	articles: `${PREFIX.current_prefix}/articles`,
	auth: `${PREFIX.auth}`,
	robots: `${PREFIX.current_prefix}/robots`,
};

const Path = {};

export { BASE_URL, PREFIX, SERVICES, Path };
