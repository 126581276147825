import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import {
	THistoryData,
	THistoryStore,
	THistoryStorePersist,
} from "./history.type";
import { INIT_HISTORY_DATA, useSeoStore } from "./seo/seo";

import {
	INIT_SETTING_DATA,
	useSettingStore,
} from "../_common/setting/settingStore";
import { mergeDataWithDefaults } from "@/libs/utils/mergeWithDefaults";
import {
	THistoryCreateReq,
	THistoryUpdateReq,
} from "@/services/API/history/history.type";

export const useHistoryStore = create<
	THistoryStore,
	[["zustand/persist", THistoryStorePersist]]
>(
	persist(
		(set, get) => ({
			id: -1,
			historyData: useSeoStore.getState().seoData,
			setting: useSettingStore.getState().settingObj.history,

			bindActionData: {
				updateHistoryData: () => {
					set((state) => ({
						...state,
						historyData: useSeoStore.getState().seoData,
						setting: useSettingStore.getState().settingObj.history,
					}));
				},
				loadHistoryData: () => {
					const storedData = get();

					if (storedData?.historyData && storedData?.setting) {
						useSeoStore.setState((state) => ({
							...state,
							seoData: {
								...INIT_HISTORY_DATA,
								...storedData?.historyData,
							},
						}));
						useSettingStore.setState((state) => ({
							settingObj: {
								...state.settingObj,
								history: {
									...INIT_SETTING_DATA,
									...storedData.setting,
								},
							},
						}));
						set((state) => ({
							...state,
							historyData: storedData.historyData,
							setting: storedData.setting,
						}));
					}
				},
				removeHistoryData: () => {
					set({
						historyData: INIT_HISTORY_DATA,
						setting: INIT_SETTING_DATA,
					});
					localStorage.removeItem("history-storage");
				},
			},
			bindTransformData: {
				getDataForRequest: ({ lang, variantRequest = "ADD" }) => {
					const storedData = get().historyData;
					let sent_object: THistoryCreateReq;
					let update_object: THistoryUpdateReq;
					sent_object = {
						story: {
							publish_at: String(new Date().toISOString()),

							name: storedData[lang].name,
							text: storedData[lang].text,
							image: storedData[lang].image,
							color: storedData.color,
							preview_image: storedData.preview_image,
							promocode: storedData.promocode,
							link: storedData.link,
						},
						language: {
							lang: lang,
						},
					};

					if (variantRequest === "UPDATE") {
						update_object = {
							...sent_object,
							story: {
								...sent_object.story,
								id: get().id || -1,
								hidden: get().setting.isHidden,
							},
						};
						return update_object;
					}

					return sent_object;
				},
				setDataFromRequest: ({ dataRu, dataEn }) => {
					let storedData = get().historyData;
					storedData = {
						color: dataRu?.data.color || "",
						link: dataRu?.data.link || "",
						preview_image: dataRu?.data.preview_image || "",
						promocode: dataRu?.data.promocode || "",
						en: {
							name: dataEn?.data.name || "",
							text: dataEn?.data.text || "",
							image: dataEn?.data.image || "",
						},
						ru: {
							name: dataRu?.data.name || "",
							text: dataRu?.data.text || "",
							image: dataRu?.data.image || "",
						},
					};

					set((state) => ({
						...state,
						id: dataRu?.data?.id || dataEn?.data?.id || -1,
						historyData: {
							...storedData,
						},
						setting: {
							...get().setting,
							isHidden:
								dataRu?.data.hidden !== undefined ? dataRu.data.hidden : true,
						},
					}));
					get().bindActionData.loadHistoryData();
					return storedData;
				},
			},

			///
		}),
		{
			name: "history-storage",
			storage: createJSONStorage(() => localStorage),
			partialize: (state) => {
				return {
					id: state?.id || -1,
					historyData: state.historyData,
					setting: state.setting,
				};
			},
		}
	)
);
