import { routes } from "@/constants/routes";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { Loader } from "@/libs/UI/Jammer";
import { useGetAllArticles } from "@/services/API/articles/hook";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import { FC, useEffect, useState } from "react";
import { HeaderSetting, Pagination } from "../_common/_comp";
import PanelLightSetting from "../_common/PanelLightSetting";
import ListAddedArticles from "./ListAddedArticles/ListAddedArticles";
import s from "./WrapperArticle.module.scss";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
const INIT_PAGIN = {
	total: 0,
	limit: 15,
	offset: 0,
};
const WrapperArticle: FC = () => {
	const { getLocalization } = useLanguage();

	const settings = useSettingStore();
	const [pagin, setPagin] = useState(INIT_PAGIN);
	const {
		data: articlesData,
		isLoading,
		refetch,
	} = useGetAllArticles({
		lang: settings.getCurrentLang("articles"),
		limit: pagin.limit,
		offset: pagin.offset,
	});
	///

	const step = (step: number) => {
		setPagin((prev) => ({
			...prev,
			offset: step,
		}));
	};

	////
	useEffect(() => {
		refetch();
	}, [pagin.limit, pagin.offset]);
	useEffect(() => {
		setPagin(INIT_PAGIN);
	}, [settings.getCurrentLang("articles")]);
	useEffect(() => {
		if (articlesData?.total) {
			setPagin((prev) => ({
				...prev,
				total: articlesData.total,
			}));
		}
	}, [articlesData?.total]);
	return (
		<div className={s.wrapper}>
			<HeaderSetting title={getLocalization("Cтатьи")} />
			<PanelLightSetting
				linkAdd={routes.ADD_ARTICLE}
				labelAdd={"Добавить статью"}
				bindLang={{
					lang: settings.getCurrentLang("articles"),
					callbackLang: (lang) => {
						console.log(lang);
						settings.updateSetting({
							_key: "currentLang",
							resource: "articles",
							value: lang,
						});
					},
				}}
			/>
			{isLoading && <Loader params={{ visible: isLoading }} />}
			{articlesData?.data && (
				<>
					<ListAddedArticles
						variantContent="articles"
						data={articlesData.data}
					/>
					{articlesData?.total > pagin.limit && (
						<Pagination
							step={step}
							total={pagin.total}
							limit={pagin.limit}
							offset={pagin.offset}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default WrapperArticle;
