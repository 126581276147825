import React, { FC, useState } from "react";
import { BASE_URL, SERVICES } from "@/constants/api";
import NotFound from "@/_assets/images/not-found.jpg";

type TProps = {
	src: string;
	alt: string;
};

const ImageCdn: FC<TProps> = ({ src, alt }) => {
	const [isError, setIsError] = useState(false);
	console.log(src);
	return (
		<img
			onError={() => setIsError(true)}
			src={isError ? NotFound : `${BASE_URL}/v1/file/image/${src}`}
			alt={alt}
		/>
	);
};

export default ImageCdn;
