import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TParamsManipulationData, TReturnData } from "./type";

export const useManipulationData = <T, To_Ser, From_Ser>(
	params: TParamsManipulationData<T, To_Ser, From_Ser>
): TReturnData<To_Ser> => {
	const {
		bindActionData,
		copyArray,
		bindTransformData,
		editFor,
		editingData,
		variantResource,
	} = params;
	const settings = useSettingStore();
	const navigate = useNavigate();
	const handleCopyStore = () => {
		return JSON.stringify(copyArray);
	};

	const handleLocalRemove = () => {
		bindActionData?.removeLocalData();
		bindActionData?.loadLocalData();
		bindActionData.updateLocalData();
		navigate(-1);
	};
	//

	const handleSentData = () => {
		const sent = bindTransformData.getDataForRequest({
			lang: settings.getCurrentLang(variantResource),
			variantRequest: editFor,
		});

		return {
			sentData: sent,
			clear: handleLocalRemove,
		};
	};

	////
	useEffect(() => {
		if (editingData?.ru || editingData?.en) {
			bindTransformData.setDataFromRequest({
				dataRu: editingData.ru,
				dataEn: editingData.en,
			});
		}
	}, [editingData]);

	useEffect(() => {
		if (editFor === "ADD") {
			bindActionData?.loadLocalData();
		} else {
		}
	}, []);

	return {
		handleCopyStore,
		handleLocalRemove,
		handleSentData,
	};
};
