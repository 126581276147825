import { TLangKey } from "@/libs/context/LanguageProvider";
import { API } from "@/services/helpers/conf-axios";
import { TParamsArticlesListReq } from "./articles.type";
import {
	TArticleCreateReq,
	TArticleDeleteRes,
	TArticlesListRes,
	TArticleUpdateReq,
	TParamsArticleUpdateReq,
	TSingleArticleRes,
} from "./articles.type";
import { SERVICES } from "@/constants/api";

export const getAllArticles = async ({
	lang,
	limit,
	offset,
}: TParamsArticlesListReq): Promise<TArticlesListRes> => {
	try {
		const response = await API.get<any, { data: TArticlesListRes }>(
			`${SERVICES.articles}/`,
			{
				params: {
					language: lang,
					limit: limit,
					offset: offset,
				},
			}
		);
		return response.data;
	} catch {
		return {
			data: [],
		} as any;
		throw new Error("fetch articles error");
	}
};

export const getArticlesById = async ({
	lang,
	id,
}: {
	lang: string;
	id: number;
}): Promise<TSingleArticleRes> => {
	try {
		const response = await API.get<any, { data: TSingleArticleRes }>(
			`${SERVICES.articles}/${id}?language=${lang}`
		);
		return response.data;
	} catch {
		throw new Error("fetch articles by id error");
	}
};

export const createArticleById = async ({
	article,
	language,
}: TArticleCreateReq): Promise<TSingleArticleRes> => {
	try {
		const response = await API.post<
			TArticleCreateReq,
			{ data: TSingleArticleRes }
		>(`${SERVICES.articles}/`, {
			article,
			language,
		});
		return response.data;
	} catch {
		throw new Error("create articles error");
	}
};

export const updateArticleById = async ({
	id,
	body,
}: TParamsArticleUpdateReq): Promise<TSingleArticleRes> => {
	try {
		const response = await API.patch<
			TArticleUpdateReq,
			{ data: TSingleArticleRes }
		>(`${SERVICES.articles}/${id}`, {
			...body,
		});
		return response.data;
	} catch {
		throw new Error("update articles by id error");
	}
};

export const deleteArticleById = async ({
	lang,
	id,
}: TArticleDeleteRes): Promise<any> => {
	try {
		const response = await API.delete(
			`${SERVICES.articles}/${id}?language=${lang}`
		);
		return response.data;
	} catch {
		throw new Error("delete articles by id error");
	}
};
