import DeleteSvg from "@/_assets/svg/DeleteSvg";
import DragSvg from "@/_assets/svg/DragSvg";
import { P } from "@/libs/UI/CustomTags";
import cn from "classnames";
import { FC, useContext, useState } from "react";
import s from "./ReceivingItem.module.scss";

import ArrowSelectorSvg from "@/_assets/svg/ArrowSelectorSvg";
import { TReturnDrag } from "@/libs/hooks/use-dragAndDrop/type";
import useRedefineDragAndDrop from "@/libs/hooks/use-redefineDragAndDrop/use-redefineDragAndDrop";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import {
	IConstructorSectionId,
	TContentTypeKey,
	TContentTypeWithId,
} from "@/store/newResourceStore/_common/constructor/types/IConstructorContent";
import { VariantNewResourceContext } from "../../../../_context/VariantNewResourceContext";
import { WrapperWithBlockPlaceInsert } from "../../_comp";
import {
	BigImage,
	DefaultText,
	List,
	ProsAndCons,
	Quote,
	RowImages,
	Table,
} from "../VariantContent";

type TProps = {
	content: TContentTypeWithId<TContentTypeKey>;
	sectionId: IConstructorSectionId;
	drag: TReturnDrag<TContentTypeWithId<TContentTypeKey>>;
};

const ReceivingItem: FC<TProps> = ({ content, sectionId, drag }) => {
	const { variantResource } = useContext(VariantNewResourceContext);
	const { bindActionContent, emdeddedStore } = useConstructorStore();
	const { removeContent } = bindActionContent;
	const { dragEvent, draggingId, wrapRef } = drag;
	const { dragRedefineEvent } = useRedefineDragAndDrop({
		dragEvent,
		workData: content,
		sectionId,
	});
	const [isCollapsed, updateCollapsed] = useState(true);

	let CurrentContent: FC<any> | null = null;
	const currentContentType = emdeddedStore.emdeddedContent.find(
		(e) => e._key === content.contentType
	);
	switch (currentContentType?._key) {
		case "defaultText":
			CurrentContent = DefaultText;
			break;
		case "bigImage":
			CurrentContent = BigImage;
			break;
		case "list":
			CurrentContent = List;
			break;
		case "prosAndCons":
			CurrentContent = ProsAndCons;
			break;
		case "quote":
			CurrentContent = Quote;
			break;
		case "rowImages":
			CurrentContent = RowImages;
			break;
		case "table":
			CurrentContent = Table;
			break;
		default:
			CurrentContent = null;
	}

	return (
		<WrapperWithBlockPlaceInsert
			dragOver={
				dragEvent.dragOverId === content.id ||
				emdeddedStore.receivingElement === content.id
			}
		>
			<div
				ref={wrapRef}
				className={cn(
					s.receivingBox,
					draggingId === content.id ? s.dragging : "",
					dragEvent.dragOverId === content.id ? s.dragOver : ""
				)}
				draggable={false}
				onDragOver={(e) => dragRedefineEvent.onRedefineOver(e, content.id)}
				onDragLeave={(e) => {
					dragRedefineEvent.onRedefineLeave(e, content.id);
				}}
				onDrop={(e) => dragRedefineEvent.onRedefineDrop(e, content)}
			>
				<div
					className={s.dragPart}
					draggable={true}
					onDragStart={(e) => dragEvent.onDragStart(e, content)}
					onDragEnd={(e) => {
						dragEvent.onDragEnd(e);
						emdeddedStore.setReceivingElement(null);
						emdeddedStore.setEmdeddedMovingElement(null);
					}}
				>
					<DragSvg />
				</div>
				{content.id !== -1 && (
					<div className={cn(s.contentPart, !isCollapsed ? s.isShow : "")}>
						<div className={s.headerContent}>
							<P size="m">{currentContentType?.label}</P>

							<div className={s.right}>
								<button
									className={isCollapsed && s.isShowx}
									onClick={() => updateCollapsed((prev) => !prev)}
								>
									<ArrowSelectorSvg />
								</button>
								<div
									className={s.removeContent}
									onClick={() => {
										removeContent({
											key: variantResource,
											section_id: sectionId,
											id: content.id,
										});
									}}
								>
									<DeleteSvg />
								</div>
							</div>
						</div>
						<div
							className={cn(s.content)}
							style={{
								overflow: "hidden",
								maxHeight: isCollapsed ? "1000px" : "0px",
								transition: "0.5s",
							}}
						>
							{CurrentContent && (
								<CurrentContent
									content={content}
									variantResource={variantResource}
									sectionId={sectionId}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</WrapperWithBlockPlaceInsert>
	);
};

export default ReceivingItem;
