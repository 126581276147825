import { routes } from "@/constants/routes";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { FC, useEffect, useState } from "react";
import { HeaderSetting, Pagination } from "../_common/_comp";
import PanelLightSetting from "../_common/PanelLightSetting";

import { Loader } from "@/libs/UI/Jammer";
import { useGetAllHistory } from "@/services/API/history/hook";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import ListAddedHistory from "./ListAddedHistory/ListAddedHistory";
import s from "./WrapperHistory.module.scss";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
const INIT_PAGIN = {
	total: 0,
	limit: 25,
	offset: 0,
};
const WrapperHistory: FC = () => {
	const { getLocalization } = useLanguage();
	const settings = useSettingStore();
	const [pagin, setPagin] = useState(INIT_PAGIN);
	const {
		data: historyData,
		isLoading,
		refetch,
	} = useGetAllHistory({
		lang: settings.getCurrentLang("history"),
		limit: pagin.limit,
		offset: pagin.offset,
	});

	///

	const step = (step: number) => {
		setPagin((prev) => ({
			...prev,
			offset: step,
		}));
	};

	////
	useEffect(() => {
		refetch();
	}, [pagin.limit, pagin.offset]);
	useEffect(() => {
		setPagin(INIT_PAGIN);
	}, [settings.getCurrentLang("history")]);
	useEffect(() => {
		if (historyData?.total) {
			setPagin((prev) => ({
				...prev,
				total: historyData.total,
			}));
		}
	}, [historyData?.total]);

	return (
		<div className={s.wrapper}>
			<HeaderSetting title={getLocalization("Истории")} />
			<PanelLightSetting
				linkAdd={routes.ADD_HISTORY}
				labelAdd={"Добавить Историю"}
				bindLang={{
					lang: settings.getCurrentLang("history"),
					callbackLang: (lang) =>
						settings.updateSetting({
							_key: "currentLang",
							resource: "history",
							value: lang,
						}),
				}}
			/>

			{isLoading && <Loader params={{ visible: isLoading }} />}
			{historyData?.data && (
				<>
					<ListAddedHistory variantContent="history" data={historyData?.data} />
					{historyData?.total > pagin.limit && (
						<Pagination
							step={step}
							total={pagin.total}
							limit={pagin.limit}
							offset={pagin.offset}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default WrapperHistory;
