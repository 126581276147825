import { routes } from "@/constants/routes";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { Loader } from "@/libs/UI/Jammer";
import { useGetTopCasino } from "@/services/API/casino/hook";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import { useEffect, useState } from "react";
import { HeaderSetting, Pagination } from "../_common/_comp";
import PanelLightSetting from "../_common/PanelLightSetting";
import ListAddedCasino from "./ListAddedCasino/ListAddedCasino";
import s from "./WrapperCasino.module.scss";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";

const INIT_PAGIN = {
	total: 0,
	limit: 15,
	offset: 0,
};
const WrapperCasino = () => {
	const { getLocalization } = useLanguage();
	const settings = useSettingStore();
	const [pagin, setPagin] = useState(INIT_PAGIN);
	const {
		data: casinoData,
		isLoading,
		refetch,
	} = useGetTopCasino({
		lang: settings.getCurrentLang("casino"),
		limit: pagin.limit,
		offset: pagin.offset,
	});

	//

	const step = (step: number) => {
		setPagin((prev) => ({
			...prev,
			offset: step,
		}));
	};
	////
	useEffect(() => {
		refetch();
	}, [pagin.limit, pagin.offset]);
	useEffect(() => {
		setPagin(INIT_PAGIN);
	}, [settings.getCurrentLang("casino")]);
	useEffect(() => {
		if (casinoData?.total) {
			setPagin((prev) => ({
				...prev,
				total: casinoData.total,
			}));
		}
	}, [casinoData?.total]);
	return (
		<div className={s.wrapper}>
			<HeaderSetting title={getLocalization("Рейтинг казино")} />
			<PanelLightSetting
				linkAdd={routes.ADD_CASINO}
				labelAdd="Добавить казино"
				bindLang={{
					lang: settings.getCurrentLang("casino"),
					callbackLang: (lang) =>
						settings.updateSetting({
							_key: "currentLang",
							resource: "casino",
							value: lang,
						}),
				}}
			/>
			{isLoading && <Loader params={{ visible: isLoading }} />}
			{casinoData?.data && (
				<>
					<ListAddedCasino variantContent="casino" data={casinoData.data} />
					{casinoData?.total > pagin.limit && (
						<Pagination
							step={step}
							total={pagin.total}
							limit={pagin.limit}
							offset={pagin.offset}
						/>
					)}
				</>
			)}
		</div>
	);
};
export default WrapperCasino;
