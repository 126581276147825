import { create } from "zustand";
import { TCreateConstructorStore } from "./types/constructorStore.type";
import { IConstructorContentSectionWithId } from "./types/IConstructorContent";
import { useSettingStore } from "../setting/settingStore";
export const INIT_CONSTRUCTOR_DATA = {
	casino: {
		ru: [],
		en: [],
	},
	news: {
		ru: [],
		en: [],
	},
	articles: {
		ru: [],
		en: [],
	},
	history: {
		ru: [],
		en: [],
	},
};
export const useConstructorStore = create<TCreateConstructorStore>(
	(set, get) => ({
		// languageStore: {
		// 	currentLang: "ru",
		// 	changeCurrentLang: (key) =>
		// 		set((state) => ({
		// 			...state,
		// 			languageStore: {
		// 				...state.languageStore,
		// 				currentLang: key,
		// 			},
		// 		})),
		// 	getCurrentLangStore: (key) => {
		// 		const currentLang = get().languageStore.currentLang;
		// 		return get().bindStore[key][currentLang] || [];
		// 	},
		// },
		currentLang: useSettingStore.getState().settingObj,
		getCurrentLang: (key) => {
			const settings = useSettingStore.getState().settingObj[key];
			return settings.currentLang;
		},
		emdeddedStore: {
			emdeddedMovingElement: null,
			receivingElement: null,
			setEmdeddedMovingElement: (element) =>
				set((state) => ({
					...state,
					emdeddedStore: {
						...state.emdeddedStore,
						emdeddedMovingElement: element,
					},
				})),
			setReceivingElement: (element) =>
				set((state) => ({
					...state,
					emdeddedStore: {
						...state.emdeddedStore,
						receivingElement: element,
					},
				})),
			emdeddedContent: [
				{ _key: "defaultText", label: "Текст" },
				{ _key: "bigImage", label: "Картинка" },
				{ _key: "rowImages", label: "2 картинки" },
				{ _key: "prosAndCons", label: "“+” и “-”" },
				{ _key: "list", label: "Cписок" },
				{ _key: "quote", label: "Цитата" },
				{ _key: "table", label: "Таблица" },
			],
		},

		bindStore: INIT_CONSTRUCTOR_DATA,
		bindActionSection: {
			setStore: ({ key, content }) =>
				set((state) => {
					const currentLang = state.currentLang[key].currentLang;
					return {
						bindStore: {
							...state.bindStore,
							[key]: {
								...state.bindStore[key],
								[currentLang]: content,
							},
						},
					};
				}),
			changeTitleSection: ({ key, id, newTitle }) =>
				set((state) => {
					const currentLang = state.currentLang[key].currentLang;
					const sectionIndex = state.bindStore[key][currentLang].findIndex(
						(e) => e.id === id
					);
					if (sectionIndex === -1) return state;
					console.log(newTitle);
					const updatedSections = [
						...state.bindStore[key][currentLang].slice(0, sectionIndex),
						{
							...state.bindStore[key][currentLang][sectionIndex],
							title: newTitle,
						},
						...state.bindStore[key][currentLang].slice(sectionIndex + 1),
					];

					return {
						bindStore: {
							...state.bindStore,
							[key]: {
								...state.bindStore[key],
								[currentLang]: updatedSections,
							},
						},
					};
				}),
			addSection: ({ key, defaultContent }) =>
				set((state) => {
					const currentLang = state.currentLang[key].currentLang;
					const newSection = {
						id: Number(new Date()),
						title: "",
						content: defaultContent
							? [
									{
										...defaultContent,
										id: Number(new Date()),
										contentType: defaultContent.contentType,
									},
							  ]
							: [],
					} as IConstructorContentSectionWithId;

					return {
						bindStore: {
							...state.bindStore,
							[key]: {
								...state.bindStore[key],
								[currentLang]: [
									...state.bindStore[key][currentLang],
									newSection,
								],
							},
						},
					};
				}),
			removeSection: ({ id, key }) =>
				set((state) => {
					const currentLang = state.currentLang[key].currentLang;
					return {
						bindStore: {
							...state.bindStore,
							[key]: {
								...state.bindStore[key],
								[currentLang]: state.bindStore[key][currentLang].filter(
									(item: IConstructorContentSectionWithId) => item.id !== id
								),
							},
						},
					};
				}),
		},
		bindActionContent: {
			setContent: ({ key, content, section_id }) =>
				set((state) => {
					const currentLang = state.currentLang[key].currentLang;
					const findIndexSection = state.bindStore[key][currentLang].findIndex(
						(e) => e.id === section_id
					);
					const currentSection =
						state.bindStore[key][currentLang][findIndexSection];

					return {
						bindStore: {
							...state.bindStore,
							[key]: {
								...state.bindStore[key],
								[currentLang]: [
									...state.bindStore[key][currentLang].slice(
										0,
										findIndexSection
									),
									{
										...currentSection,
										content,
									},
									...state.bindStore[key][currentLang].slice(
										findIndexSection + 1
									),
								],
							},
						},
					};
				}),
			addContent: ({ key, section_id, content, contentBeforeId = -1 }) =>
				set((state) => {
					const currentLang = state.currentLang[key].currentLang;
					const findIndexSection = state.bindStore[key][currentLang].findIndex(
						(e) => e.id === section_id
					);
					const currentSection =
						state.bindStore[key][currentLang][findIndexSection];
					const currentContent = currentSection.content;

					const findIndexContentBefore = currentContent.findIndex(
						(e) => e.id === contentBeforeId
					);
					let newContent;
					if (findIndexContentBefore > -1) {
						newContent = [
							...currentContent.slice(0, findIndexContentBefore + 1),
							content,
							...currentContent.slice(findIndexContentBefore + 1),
						];
					} else {
						newContent = [...currentContent, content];
					}
					const replaceEmpty = newContent.filter((e) => e.id !== -1);
					return {
						bindStore: {
							...state.bindStore,
							[key]: {
								...state.bindStore[key],
								[currentLang]: [
									...state.bindStore[key][currentLang].slice(
										0,
										findIndexSection
									),
									{
										...currentSection,
										content: replaceEmpty,
									},
									...state.bindStore[key][currentLang].slice(
										findIndexSection + 1
									),
								],
							},
						},
					};
				}),
			removeContent: ({ key, section_id, id }) =>
				set((state) => {
					const currentLang = state.currentLang[key].currentLang;
					const findIndexSection = state.bindStore[key][currentLang].findIndex(
						(e) => e.id === section_id
					);
					const currentSection =
						state.bindStore[key][currentLang][findIndexSection];
					const findIndexContent = currentSection.content.findIndex(
						(e) => e.id === id
					);

					return {
						bindStore: {
							...state.bindStore,
							[key]: {
								...state.bindStore[key],
								[currentLang]: [
									...state.bindStore[key][currentLang].slice(
										0,
										findIndexSection
									),
									{
										...currentSection,
										content: [
											...currentSection.content.slice(0, findIndexContent),
											...currentSection.content.slice(findIndexContent + 1),
										],
									},
									...state.bindStore[key][currentLang].slice(
										findIndexSection + 1
									),
								],
							},
						},
					};
				}),
			changeContent: ({ key, section_id, id, content }) =>
				set((state) => {
					const currentLang = state.currentLang[key].currentLang;
					const findIndexSection = state.bindStore[key][currentLang].findIndex(
						(e) => e.id === section_id
					);
					const currentSection =
						state.bindStore[key][currentLang][findIndexSection];
					const findIndexContent = currentSection.content.findIndex(
						(e) => e.id === id
					);

					return {
						bindStore: {
							...state.bindStore,
							[key]: {
								...state.bindStore[key],
								[currentLang]: [
									...state.bindStore[key][currentLang].slice(
										0,
										findIndexSection
									),
									{
										...currentSection,
										content: [
											...currentSection.content.slice(0, findIndexContent),
											content,
											...currentSection.content.slice(findIndexContent + 1),
										],
									},
									...state.bindStore[key][currentLang].slice(
										findIndexSection + 1
									),
								],
							},
						},
					};
				}),
		},
	})
);
