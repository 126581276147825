import { create } from "zustand";
import { TSeoState } from "./seo.type";
// metaRus metaEng
export const INIT_SEO_DATA = {
	name: "",
	ru: {
		link: "",
		metaTitle: "",
		metaDescription: "",
		title: "",
		description: "",
	},
	en: {
		link: "",
		metaTitle: "",
		metaDescription: "",
		title: "",
		description: "",
	},
	fileURL: "",
};
export const useSeoStore = create<TSeoState>((set) => ({
	seoData: INIT_SEO_DATA,
	setSeoData: ({ field, value, lang = null }) =>
		set((state) => {
			if (!lang) {
				return {
					seoData: {
						...state.seoData,
						[field]: value,
					},
				};
			}
			return {
				seoData: {
					...state.seoData,
					[lang]: {
						...state.seoData[lang],
						[field]: value,
					},
				},
			};
		}),
}));
