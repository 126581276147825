import { routes } from "@/constants/routes";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { FC, useEffect, useState } from "react";
import { HeaderSetting, Pagination } from "../_common/_comp";
import PanelLightSetting from "../_common/PanelLightSetting";

import { Loader } from "@/libs/UI/Jammer";
import { useGetAllNews } from "@/services/API/news/hook";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import ListAddedNews from "./ListAddedNews/ListAddedNews";
import s from "./WrapperNews.module.scss";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
const INIT_PAGIN = {
	total: 0,
	limit: 15,
	offset: 0,
};
const WrapperNews: FC = () => {
	const { getLocalization } = useLanguage();
	const {
		settingObj: {
			news: { currentLang },
		},
		updateSetting,
	} = useSettingStore();
	const [pagin, setPagin] = useState(INIT_PAGIN);

	const {
		data: newsData,
		isLoading,
		refetch,
	} = useGetAllNews({
		lang: currentLang,
		limit: pagin.limit,
		offset: pagin.offset,
	});

	////

	const step = (step: number) => {
		setPagin((prev) => ({
			...prev,
			offset: step,
		}));
	};

	////
	useEffect(() => {
		refetch();
	}, [pagin.limit, pagin.offset]);

	useEffect(() => {
		setPagin(INIT_PAGIN);
	}, [currentLang]);

	useEffect(() => {
		if (newsData?.total) {
			setPagin((prev) => ({
				...prev,
				total: newsData.total,
			}));
		}
	}, [newsData?.total]);
	return (
		<div className={s.wrapper}>
			<HeaderSetting title={getLocalization("Новости")} />
			<PanelLightSetting
				linkAdd={routes.ADD_NEWS}
				labelAdd={"Добавить новость"}
				bindLang={{
					lang: currentLang,
					callbackLang: (lang) =>
						updateSetting({
							_key: "currentLang",
							resource: "news",
							value: lang,
						}),
				}}
			/>
			{isLoading && <Loader params={{ visible: isLoading }} />}
			{newsData?.data && (
				<>
					<ListAddedNews variantContent="news" data={newsData.data} />

					{newsData?.total > pagin.limit && (
						<Pagination
							step={step}
							total={pagin.total}
							limit={pagin.limit}
							offset={pagin.offset}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default WrapperNews;
