import { P } from "@/libs/UI/CustomTags";
import cn from "classnames";
import { FC } from "react";
import { TStatusSelector, TStatusSelectorKey } from "../../type";
import s from "./ItemStatus.module.scss";
import ArrowSelectorSvg from "@/_assets/svg/ArrowSelectorSvg";

type TProps = {
	dataSelector: TStatusSelector[];
	updateDataSelector: (key: TStatusSelectorKey) => void;
};
const ItemStatus: FC<TProps> = ({ dataSelector, updateDataSelector }) => {
	const activeItem = dataSelector.find((e) => e.active);
	return (
		<>
			{activeItem && (
				<div className={cn(s.item, s.itemChosen, s[activeItem.key])}>
					<div className={s.box}>
						{activeItem.key !== "all" && <div />}
						<P size="s">{activeItem.label}</P>
					</div>
					<ArrowSelectorSvg />
				</div>
			)}

			{dataSelector.map((item) => {
				return (
					<div
						key={item.key}
						className={cn(s.item, s[item.key], { [s.isActive]: item.active })}
						onClick={() => updateDataSelector(item.key)}
					>
						<div className={s.box}>
							<div />
							<P size="s">{item.label}</P>
						</div>
					</div>
				);
			})}
		</>
	);
};
export default ItemStatus;
