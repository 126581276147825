import s from "./SeoResource.module.scss";

import { ColorPicker } from "@/features/Constructor/_common/_comp";
import Select from "@/features/Constructor/_common/_comp/Select/Select";
import UploadImage from "@/features/Constructor/_common/_comp/UploadImage/UploadImage";
import { Input } from "@/libs/UI";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import { useSeoStore } from "@/store/newResourceStore/article";
import { TTypeTagArticle } from "@/store/newResourceStore/article/seo/seo.type";

const testData = [
	{
		id: 1,
		title: "Партнерские программы",
	},
	{
		id: 2,
		title: "Аналитика слотов",
	},
	{
		id: 3,
		title: "Интервью",
	},
	{
		id: 4,
		title: "Правила игр",
	},
	{
		id: 5,
		title: "Знаменитости",
	},
	{
		id: 7,
		title: "Топ слотов",
	},
	{
		id: 8,
		title: "Культовые казино",
	},
] as TTypeTagArticle[];

const Added = (item: TTypeTagArticle) => {
	return <p>{item.title}</p>;
};
const Feature = (item: TTypeTagArticle) => {
	return <p>{item.title}</p>;
};
const SeoResource = () => {
	const { seoData, setSeoData, setTags } = useSeoStore();
	const settings = useSettingStore();
	return (
		<div className={s.seo}>
			<div className={s.inputFile_block}>
				<p>Обложка</p>
				<div className={s.inputFile}>
					<UploadImage
						fileURL={seoData.fileURL}
						onChange={(e) => {
							setSeoData({ field: "fileURL", value: e });
						}}
						idInput={"seo"}
					/>
				</div>
			</div>

			<div className={s.colorPicker}>
				<ColorPicker
					label="Цвет новости"
					activeColor={seoData.color}
					onChange={(color) => {
						setSeoData({ field: "color", value: color });
					}}
				/>
			</div>
			<Input
				placeholder="Ссылка на кнопку"
				label="Ссылка на кнопку"
				value={seoData.btn_url}
				onChange={(e) =>
					setSeoData({ field: "btn_url", value: e.target.value })
				}
			/>
			{settings.getCurrentLang("articles") === "ru" && (
				<div className={s.inputs_block}>
					<p>Meta RUS</p>
					<div className={s.inputs}>
						<Input
							placeholder="Ccылка"
							value={seoData.ru.link}
							onChange={(e) => {
								setSeoData({
									field: "link",
									value: e.target.value,
									lang: "ru",
								});
							}}
						/>
						<Input
							placeholder="Meta Title"
							value={seoData.ru.metaTitle}
							onChange={(e) => {
								setSeoData({
									field: "metaTitle",
									value: e.target.value,
									lang: "ru",
								});
							}}
						/>
						<Input
							placeholder="Meta Description"
							value={seoData.ru.metaDescription}
							onChange={(e) =>
								setSeoData({
									field: "metaDescription",
									value: e.target.value,
									lang: "ru",
								})
							}
						/>
						<Input
							placeholder="Title"
							value={seoData.ru.title}
							onChange={(e) =>
								setSeoData({
									field: "title",
									value: e.target.value,
									lang: "ru",
								})
							}
						/>
						<Input
							placeholder="Description"
							value={seoData.ru.description}
							onChange={(e) =>
								setSeoData({
									field: "description",
									value: e.target.value,
									lang: "ru",
								})
							}
						/>

						<Select
							dataAdded={seoData.ru.tags}
							value={testData}
							DataComponent={(e) => (
								<Added title={e.item.title} id={e.item.id} />
							)}
							ValueComponent={(e) => (
								<Feature title={e.item.title} id={e.item.id} />
							)}
							placeholder="Tags"
							onChange={(e) => setTags({ field: "tags", tag: e, lang: "ru" })}
						/>
					</div>
				</div>
			)}
			{settings.getCurrentLang("articles") === "en" && (
				<div className={s.inputs_block}>
					<p>Meta ENG</p>
					<div className={s.inputs}>
						<Input
							placeholder="Ccылка"
							value={seoData.en.link}
							onChange={(e) => {
								setSeoData({
									field: "link",
									value: e.target.value,
									lang: "en",
								});
							}}
						/>
						<Input
							placeholder="Meta Title"
							value={seoData.en.metaTitle}
							onChange={(e) => {
								setSeoData({
									field: "metaTitle",
									value: e.target.value,
									lang: "en",
								});
							}}
						/>
						<Input
							placeholder="Meta Description"
							value={seoData.en.metaDescription}
							onChange={(e) =>
								setSeoData({
									field: "metaDescription",
									value: e.target.value,
									lang: "en",
								})
							}
						/>
						<Input
							placeholder="Title"
							value={seoData.en.title}
							onChange={(e) =>
								setSeoData({
									field: "title",
									value: e.target.value,
									lang: "en",
								})
							}
						/>
						<Input
							placeholder="Description"
							value={seoData.en.description}
							onChange={(e) =>
								setSeoData({
									field: "description",
									value: e.target.value,
									lang: "en",
								})
							}
						/>
						<Select
							dataAdded={seoData.en.tags}
							value={testData}
							DataComponent={(e) => (
								<Added title={e.item.title} id={e.item.id} />
							)}
							ValueComponent={(e) => (
								<Feature title={e.item.title} id={e.item.id} />
							)}
							placeholder="Tags"
							onChange={(e) => setTags({ field: "tags", tag: e, lang: "ru" })}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default SeoResource;
