import { toastGetAllLang } from "@/libs/utils/toastGetAllLang";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
	createNewsById,
	deleteNewsById,
	getAllNews,
	getNewsById,
	updateNewsById,
} from "./news";
import {
	TNewsCreateReq,
	TNewsDeleteRes,
	TParamsNewsListReq,
	TParamsNewsUpdateReq,
	TParamsSingleNewsAllLangReq,
	TParamsSingleNewsReq,
} from "./news.type";

export const useGetAllNews = (params: TParamsNewsListReq) => {
	return useQuery(["news", params.lang], () => getAllNews(params), {
		onSuccess: (data) => {},
		onError: (error) => {
			console.log("get all news", error);
		},
	});
};
export const useGetNewsById = ({ lang, id }: TParamsSingleNewsReq) => {
	return useQuery(["news", id, lang], () => getNewsById({ lang, id }), {
		onSuccess: (data) => {},
		onError: (error) => {
			console.log("get news", error);
		},
	});
};

export const useGetNewsAllLangById = () => {
	return useMutation(
		async ({ id }: TParamsSingleNewsAllLangReq) => {
			const [dataRu, dataEn] = await Promise.allSettled([
				getNewsById({ lang: "ru", id }),
				getNewsById({ lang: "en", id }),
			]);
			toastGetAllLang({
				en: dataEn.status === "fulfilled",
				ru: dataRu.status === "fulfilled",
			});

			return {
				dataRu: dataRu.status === "fulfilled" ? dataRu.value : null,
				dataEn: dataEn.status === "fulfilled" ? dataEn.value : null,
				errors: {
					ru: dataRu.status === "rejected" ? dataRu.reason : null,
					en: dataEn.status === "rejected" ? dataEn.reason : null,
				},
			};
		},
		{
			onSuccess: (data) => {},
			onError: (error) => {
				console.log("get all langs news", error);
			},
		}
	);
};

export const useCreateNews = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ news, language }: TNewsCreateReq) => {
			return await createNewsById({ news, language });
		},
		{
			onSuccess: (data) => {
				toast.success("Новость добавлена");
				queryClient.invalidateQueries("news");
			},
			onError: (error) => {
				toast.error("Ошибка добавления");
				console.log("create news", error);
			},
		}
	);
};
export const useUpdateNewsById = () => {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ id, body }: TParamsNewsUpdateReq) => {
			console.log(id, body);
			return await updateNewsById({ id, body });
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("news");
				toast.success("Новость обновлена");
			},
			onError: (error) => {
				toast.error("Ошибка обновления");
				console.log("update news", error);
			},
		}
	);
};

export const useDeleteNewsById = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ lang, id }: TNewsDeleteRes) => {
			return await deleteNewsById({ lang, id });
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("news");
				toast.success("Новость удалена");
			},
			onError: (error) => {
				toast.error("Ошибка удаления");
				console.log("delete news", error);
			},
		}
	);
};
