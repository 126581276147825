import { API } from "@/services/helpers/conf-axios";
import {
	TNewsCreateReq,
	TNewsDeleteRes,
	TNewsListRes,
	TNewsUpdateReq,
	TParamsNewsListReq,
	TParamsNewsUpdateReq,
	TParamsSingleNewsReq,
	TSingleNewsRes,
} from "./news.type";
import { SERVICES } from "@/constants/api";

export const getAllNews = async ({
	lang,
	limit,
	offset,
}: TParamsNewsListReq): Promise<TNewsListRes> => {
	try {
		const response = await API.get<any, { data: TNewsListRes }>(
			`${SERVICES.news}/`,
			{
				params: {
					language: lang,
					limit: limit,
					offset: offset,
				},
			}
		);
		return response.data;
	} catch {
		return {
			data: [],
		} as any;
		throw new Error("fetch news error");
	}
};

export const getNewsById = async ({
	lang,
	id,
}: TParamsSingleNewsReq): Promise<TSingleNewsRes> => {
	try {
		const response = await API.get<any, { data: TSingleNewsRes }>(
			`${SERVICES.news}/${id}?language=${lang}`
		);
		return response.data;
	} catch {
		throw new Error("fetch news by id error");
	}
};

export const createNewsById = async ({
	news,
	language,
}: TNewsCreateReq): Promise<TSingleNewsRes> => {
	try {
		const response = await API.post<TNewsCreateReq, { data: TSingleNewsRes }>(
			`${SERVICES.news}/`,
			{
				news,
				language,
			}
		);
		return response.data;
	} catch {
		throw new Error("create news error");
	}
};

export const updateNewsById = async ({
	id,
	body,
}: TParamsNewsUpdateReq): Promise<TSingleNewsRes> => {
	try {
		const response = await API.patch<TNewsUpdateReq, { data: TSingleNewsRes }>(
			`${SERVICES.news}/${id}`,
			{ ...body }
		);
		return response.data;
	} catch {
		throw new Error("update news by id error");
	}
};

export const deleteNewsById = async ({
	lang,
	id,
}: TNewsDeleteRes): Promise<any> => {
	try {
		const response = await API.delete(
			`${SERVICES.news}/${id}?language=${lang}`
		);
		return response.data;
	} catch {
		throw new Error("delete news by id error");
	}
};
