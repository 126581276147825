import LangSelector from "@/components/LangSelector/LangSelector";
import { InputSearch } from "@/libs/UI";
import { TLangKey, useLanguage } from "@/libs/context/LanguageProvider";
import { useInput } from "@/libs/hooks/use-input";
import { FC, useCallback, useState } from "react";
import s from "./PanelLightSetting.module.scss";
import StatusFilter from "./StatusFilter";
import AddNewItem from "./addNewItem/AddNewItem";
import { TStatusSelector, TStatusSelectorKey } from "./type";
type TProps = {
	linkAdd: string;
	labelAdd: string;
	bindLang: {
		lang?: TLangKey;
		callbackLang?: (params: TLangKey) => void;
	};
};
const PanelLightSetting: FC<TProps> = ({ linkAdd, labelAdd, bindLang }) => {
	const { onChangeText, value } = useInput();
	const { getLocalization } = useLanguage();

	const [dataSelector, setDataSelector] = useState<TStatusSelector[]>([
		{ key: "all", label: getLocalization("Все"), active: true },
		{ key: "active", label: getLocalization("Активый"), active: false },
		{ key: "inactive", label: getLocalization("Не активный"), active: false },
	]);
	///
	const updateDataSelector = useCallback((key: TStatusSelectorKey) => {
		setDataSelector((prev) => {
			const findIndex = prev.findIndex((e) => e.key === key);
			if (prev[findIndex].active) return prev;

			const newArray = [...prev].map((e) => ({ ...e, active: false }));

			return [
				...newArray.slice(0, findIndex),
				{ ...newArray[findIndex], active: true },
				...newArray.slice(findIndex + 1),
			];
		});
	}, []);
	return (
		<div className={s.panelLightSetting}>
			<div className={s.filters}>
				<InputSearch {...{ onChangeText, value }} placeholder="Cats..." />
				<StatusFilter
					dataSelector={dataSelector}
					updateDataSelector={updateDataSelector}
				/>
				<div className={s.lang}>
					<LangSelector
						activeLang={bindLang.lang || "ru"}
						callback={(lang) => {
							if (bindLang.callbackLang) {
								bindLang.callbackLang(lang);
							}
						}}
					/>
				</div>
			</div>
			<div>
				<AddNewItem labelBtn={getLocalization(labelAdd)} link={linkAdd} />
			</div>
		</div>
	);
};
export default PanelLightSetting;
