import { FC, ReactNode, useEffect, useRef, useState } from "react";
import s from "./SelectorHOC.module.scss";
type TProps = {
	children: ReactNode;
	background?: string;
};
const SelectorHOC: FC<TProps> = ({ children, background = "transparent" }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [height, setHeight] = useState<string | null>(null);
	const refBlock = useRef<HTMLDivElement>(null);
	useEffect(() => {
		setHeight(`calc(var(--s21) + var(--s12) * 2)`);
	}, []);
	useEffect(() => {
		if (refBlock.current) {
			if (isOpen) {
				setHeight(`${refBlock.current.scrollHeight}px`);
			} else {
				setHeight(`calc(var(--s20) + var(--s12) * 2)`);
			}
		}
	}, [isOpen]);
	///
	const handleOpenSelector = () => {
		setIsOpen((prev) => !prev);
	};
	return (
		<div className={s.wrapper}>
			{height && (
				<div
					className={s.selector}
					ref={refBlock}
					style={{ height, background: background }}
					onClick={handleOpenSelector}
				>
					{children}
				</div>
			)}
		</div>
	);
};

export default SelectorHOC;
