import React, { FC, InputHTMLAttributes, useState } from "react";
import s from "./Input.module.scss";
import cn from "classnames";
import ShowPass from "@/_assets/svg/ShowPass";
import HidePass from "@/_assets/svg/HidePass";

export interface IInput extends InputHTMLAttributes<HTMLInputElement> {
	icon?: boolean;
	error?: string | null;
	label?: string;
}

const Input: FC<IInput> = ({ icon, type, label, error, ...props }) => {
	const [viewPass, setViewPass] = useState(false);
	const isPassword = type === "password";

	return (
		<div className={s.labelWrapper}>
			{label && <label>{label}</label>}
			<div
				className={cn(s.inputWrapper, {
					[s.errorBorder]: error,
				})}
			>
				<input
					{...props}
					className={s.input}
					value={props?.value || ""}
					type={isPassword && viewPass ? "text" : type}
				/>
				{icon && isPassword && (
					<div className={s.icon} onClick={() => setViewPass(!viewPass)}>
						{!viewPass ? <ShowPass /> : <HidePass />}
					</div>
				)}
				{error && <p className={s.error}>{error}</p>}
			</div>
		</div>
	);
};

export default Input;
