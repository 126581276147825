import { useLanguage } from "@/libs/context/LanguageProvider";
import { FC, useEffect, useState } from "react";
import { TTabItem } from "../../../../components/Tabs/type";
import EmbeddedContent from "../../_common/AddNewResource/EmbeddedContent/EmbeddedContent";
import ReceivingData from "../../_common/AddNewResource/ReceivingData/ReceivingData";
import SettingWidget from "../../_common/AddNewResource/SettingWidget/SettingWidget";
import s from "./WrapperAddNewNews.module.scss";

import Tabs from "@/components/Tabs/Tabs";
import WrapperAddNewResource from "../../_common/_comp/WrapperAddNewResource";

import useTabs from "@/libs/hooks/use-tabs";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import useNewsStore from "@/store/newResourceStore/news";
import { TNewsKeysTabs } from "@/store/newResourceStore/news/newsStore.type";
import { HeaderActions } from "../../_common/_comp";
import { useManipulationData } from "../../_common/_hooks";
import { TEditingData } from "../../_common/_hooks/use-manipulation-data/type";
import { TVariantOpenPage } from "../../Constructor.type";
import SeoResource from "./SeoResource/SeoResource";
import { useNavigate } from "react-router-dom";
import {
	useCreateNews,
	useDeleteNewsById,
	useGetNewsAllLangById,
	useUpdateNewsById,
} from "@/services/API/news/hook";
import {
	TNewsCreateReq,
	TNewsUpdateReq,
	TSingleNewsRes,
} from "@/services/API/news/news.type";
import { Loader } from "@/libs/UI/Jammer";
import { VariantNewResourceContext } from "../../_common/AddNewResource/ReceivingData/_context/VariantNewResourceContext";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";

const INIT_TABS = [
	{
		key: "seo",
		label: "SEO",
		isActive: true,
	},
	{
		key: "content",
		label: "Контент",
		isActive: false,
	},
] as TTabItem<TNewsKeysTabs>[];
const WrapperAddNewNews: FC<TVariantOpenPage> = ({
	editFor,
	labelPage,
	id = -1,
}) => {
	const { getLocalization } = useLanguage();
	const { activeTab, changeTabs, tabs } = useTabs<TNewsKeysTabs>(INIT_TABS);
	const { newsObj, bindActionData, bindTransformData } = useNewsStore();
	const { bindStore } = useConstructorStore();
	const settings = useSettingStore();

	/////////

	const [editingData, setEditingData] = useState<TEditingData<TSingleNewsRes>>({
		ru: null,
		en: null,
	});

	const { mutateAsync: createItem } = useCreateNews();
	const { mutateAsync: deleteItem } = useDeleteNewsById();
	const { mutateAsync: getAllLangItem } = useGetNewsAllLangById();
	const { mutateAsync: updateItem } = useUpdateNewsById();

	/////////

	const { handleSentData, handleCopyStore, handleLocalRemove } =
		useManipulationData({
			bindActionData: {
				loadLocalData: bindActionData.loadNewsData,
				removeLocalData: bindActionData.removeNewsData,
				updateLocalData: bindActionData.updateNewsData,
			},
			copyArray: bindStore.news[settings.getCurrentLang("news")],
			bindTransformData,
			variantResource: "news",
			editingData,
			editFor,
		});

	const fetchSentData = async () => {
		const sentSetting = handleSentData();
		if (sentSetting !== null) {
			if (editFor === "ADD") {
				await createItem(sentSetting.sentData as TNewsCreateReq).then(() => {
					sentSetting.clear();
				});
			}
			if (editFor === "UPDATE") {
				await updateItem({
					id,
					body: sentSetting.sentData as TNewsUpdateReq,
				}).then(() => {
					sentSetting.clear();
				});
			}
		}
	};

	const fetchDeleteNews = async () => {
		const _id = id || newsObj?.id || -1;
		await deleteItem({
			lang: settings.getCurrentLang("news"),
			id: _id,
		}).then(() => {
			handleLocalRemove();
		});
	};

	/////////

	useEffect(() => {
		if (id > -1) {
			getAllLangItem({ id }).then((e) => {
				setEditingData({
					en: e.dataEn,
					ru: e.dataRu,
				});
			});
		}
	}, [id]);

	if (editFor === "UPDATE" && !editingData.ru && !editingData.en)
		return (
			<Loader
				params={{
					visible: !editingData.ru && !editingData.en,
				}}
			/>
		);
	return (
		<VariantNewResourceContext.Provider value={{ variantResource: "news" }}>
			<WrapperAddNewResource
				title={getLocalization(labelPage)}
				goBack={true}
				rightComp={
					<HeaderActions
						disabled={false}
						handleUpdateStore={bindActionData.updateNewsData}
						handleCopyStore={handleCopyStore}
					/>
				}
			>
				<div className={s.wrap}>
					<div className={s.main_block}>
						<Tabs
							data={tabs}
							callback={changeTabs}
							saveData={bindActionData.updateNewsData}
						/>
						{activeTab.key === "seo" && <SeoResource />}
						{activeTab.key === "content" && <ReceivingData />}
					</div>
					<div className={s.widgets_block}>
						{activeTab.key === "content" && <EmbeddedContent />}
						<SettingWidget
							handleRemove={fetchDeleteNews}
							handleSent={fetchSentData}
							editFor={editFor}
						/>
					</div>
				</div>
			</WrapperAddNewResource>
		</VariantNewResourceContext.Provider>
	);
};
export default WrapperAddNewNews;
