import UploadImage from "@/features/Constructor/_common/_comp/UploadImage/UploadImage";
import { Input } from "@/libs/UI";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";
import { useSeoStore } from "@/store/newResourceStore/casino";
import s from "./SeoResource.module.scss";

const SeoResource = () => {
	const { seoData, setSeoData } = useSeoStore();
	const settings = useSettingStore();
	return (
		<div className={s.seo}>
			<div className={s.inputFile_block}>
				<p>Логотип</p>
				<div className={s.inputFile}>
					<UploadImage
						fileURL={seoData.fileURL}
						onChange={(e) => setSeoData({ field: "fileURL", value: e })}
						idInput={"seo"}
					/>
				</div>
			</div>
			<Input
				placeholder="Название казино"
				value={seoData.name}
				onChange={(e) => setSeoData({ field: "name", value: e.target.value })}
			/>
			{settings.getCurrentLang("casino") === "ru" && (
				<div className={s.inputs_block}>
					<p>Meta RUS</p>
					<div className={s.inputs}>
						<Input
							placeholder="Ссылка"
							value={seoData.ru.link}
							onChange={(e) => {
								setSeoData({
									field: "link",
									value: e.target.value,
									lang: "ru",
								});
							}}
						/>

						<Input
							placeholder="Meta Title"
							value={seoData.ru.metaTitle}
							onChange={(e) => {
								setSeoData({
									field: "metaTitle",
									value: e.target.value,
									lang: "ru",
								});
							}}
						/>
						<Input
							placeholder="Meta Description"
							value={seoData.ru.metaDescription}
							onChange={(e) =>
								setSeoData({
									field: "metaDescription",
									value: e.target.value,
									lang: "ru",
								})
							}
						/>
						<Input
							placeholder="Title"
							value={seoData.ru.title}
							onChange={(e) =>
								setSeoData({
									field: "title",
									value: e.target.value,
									lang: "ru",
								})
							}
						/>
						<Input
							placeholder="Description"
							value={seoData.ru.description}
							onChange={(e) =>
								setSeoData({
									field: "description",
									value: e.target.value,
									lang: "ru",
								})
							}
						/>
					</div>
				</div>
			)}
			{settings.getCurrentLang("casino") === "en" && (
				<div className={s.inputs_block}>
					<p>Meta ENG</p>
					<div className={s.inputs}>
						<Input
							placeholder="Ссылка"
							value={seoData.en.link}
							onChange={(e) => {
								setSeoData({
									field: "link",
									value: e.target.value,
									lang: "ru",
								});
							}}
						/>
						<Input
							placeholder="Meta Title"
							value={seoData.en.metaTitle}
							onChange={(e) => {
								setSeoData({
									field: "metaTitle",
									value: e.target.value,
									lang: "en",
								});
							}}
						/>
						<Input
							placeholder="Meta Description"
							value={seoData.en.metaDescription}
							onChange={(e) =>
								setSeoData({
									field: "metaDescription",
									value: e.target.value,
									lang: "en",
								})
							}
						/>
						<Input
							placeholder="Title"
							value={seoData.en.title}
							onChange={(e) =>
								setSeoData({
									field: "title",
									value: e.target.value,
									lang: "en",
								})
							}
						/>
						<Input
							placeholder="Description"
							value={seoData.en.description}
							onChange={(e) =>
								setSeoData({
									field: "description",
									value: e.target.value,
									lang: "en",
								})
							}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default SeoResource;
