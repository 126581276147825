import { toastGetAllLang } from "@/libs/utils/toastGetAllLang";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { TParamsHistoryListReq, TParamsSingleNewsReq } from "../news/news.type";
import {
	createHistoryById,
	deleteHistoryById,
	getAllHistory,
	getHistoryById,
	updateHistoryById,
} from "./history";
import {
	THistoryCreateReq,
	THistoryDeleteRes,
	TParamsHistoryUpdateReq,
	TParamsSingleHistoryAllLangReq,
} from "./history.type";

export const useGetAllHistory = (params: TParamsHistoryListReq) => {
	return useQuery(["allHistory", params.lang], () => getAllHistory(params), {
		onSuccess: (data) => {
			console.log("History fetched successfully:", data);
		},
		onError: (error) => {
			console.error("Error fetching history:", error);
		},
	});
};

export const useGetHistoryById = ({ lang, id }: TParamsSingleNewsReq) => {
	return useQuery(["history", id, lang], () => getHistoryById({ lang, id }), {
		onSuccess: (data) => {
			console.log("History fetched successfully by id:", data);
		},
		onError: (error) => {
			console.error("Error fetching history by id:", error);
		},
	});
};

export const useGetHistoryAllLangById = () => {
	return useMutation(
		async ({ id }: TParamsSingleHistoryAllLangReq) => {
			const [dataRu, dataEn] = await Promise.allSettled([
				getHistoryById({ lang: "ru", id }),
				getHistoryById({ lang: "en", id }),
			]);
			toastGetAllLang({
				en: dataEn.status === "fulfilled",
				ru: dataRu.status === "fulfilled",
			});
			return {
				dataRu: dataRu.status === "fulfilled" ? dataRu.value : null,
				dataEn: dataEn.status === "fulfilled" ? dataEn.value : null,
				errors: {
					ru: dataRu.status === "rejected" ? dataRu.reason : null,
					en: dataEn.status === "rejected" ? dataEn.reason : null,
				},
			};
		},
		{
			onSuccess: (data) => {},
			onError: (error) => {
				console.log("get all langs history", error);
			},
		}
	);
};

export const useCreateHistory = () => {
	return useMutation(
		async ({ story, language }: THistoryCreateReq) => {
			return await createHistoryById({ story, language });
		},
		{
			onSuccess: (data) => {
				toast.success("История добавлена");
			},
			onError: (error) => {
				toast.error("Ошибка добавления");
				console.log("create history", error);
			},
		}
	);
};

export const useUpdateHistoryById = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ id, body }: TParamsHistoryUpdateReq) => {
			return await updateHistoryById({ id, body });
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("allHistory");
				toast.success("История обновлена");
			},
			onError: (error) => {
				toast.error("Ошибка обновления");
				console.log("update history", error);
			},
		}
	);
};

export const useDeleteHistoryById = () => {
	return useMutation(
		async ({ lang, id }: THistoryDeleteRes) => {
			return await deleteHistoryById({ lang, id });
		},
		{
			onSuccess: (data) => {
				toast.success("История удалена");
			},
			onError: (error) => {
				toast.error("Ошибка удаления");
				console.log("delete history", error);
			},
		}
	);
};
