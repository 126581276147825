import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
	INIT_CONSTRUCTOR_DATA,
	useConstructorStore,
} from "../_common/constructor/constructorStore";
import {
	TNewsDataStore,
	TNewsStore,
	TNewsStorePersist,
} from "./newsStore.type";
import { INIT_SEO_DATA, useSeoStore } from "./seo/seo";
import {
	INIT_SETTING_DATA,
	useSettingStore,
} from "../_common/setting/settingStore";
import { TNewsCreateReq, TNewsUpdateReq } from "@/services/API/news/news.type";

const INIT_NEWS_OBJECT = {
	id: -1,
	seoStore: INIT_SEO_DATA,
	contentStore: INIT_CONSTRUCTOR_DATA.news,
};
export const useNewsStore = create<
	TNewsStore,
	[["zustand/persist", TNewsStorePersist]]
>(
	persist(
		(set, get) => {
			return {
				newsObj: {
					id: -1,
					seoStore: useSeoStore.getState().seoData,
					contentStore: useConstructorStore.getState().bindStore.news,
				},
				setting: useSettingStore.getState().settingObj.news,

				bindActionData: {
					updateNewsData: () => {
						set((state) => ({
							...state,
							newsObj: {
								...state.newsObj,
								contentStore: useConstructorStore.getState().bindStore.news,
								seoStore: useSeoStore.getState().seoData,
							},
							setting: useSettingStore.getState().settingObj.news,
						}));
					},
					loadNewsData: () => {
						const storedData = get();
						if (storedData?.newsObj && storedData?.setting) {
							useConstructorStore.setState((state) => ({
								...state,
								bindStore: {
									...INIT_CONSTRUCTOR_DATA,
									news: storedData.newsObj.contentStore,
								},
							}));
							useSeoStore.setState((state) => ({
								...state,
								seoData: {
									...INIT_NEWS_OBJECT.seoStore,
									...storedData.newsObj.seoStore,
								},
							}));
							useSettingStore.setState((state) => ({
								settingObj: {
									...state.settingObj,
									news: {
										...INIT_SETTING_DATA,
										...storedData.setting,
									},
								},
							}));
							set((state) => ({
								...state,
								newsObj: storedData.newsObj,
								setting: storedData.setting,
							}));
						}
					},
					removeNewsData: () => {
						set({
							newsObj: INIT_NEWS_OBJECT,
							setting: INIT_SETTING_DATA,
						});
						localStorage.removeItem("news-storage");
					},
				},
				bindTransformData: {
					getDataForRequest: ({ lang, variantRequest = "ADD" }) => {
						const storedData = get().newsObj;
						let sent_object: TNewsCreateReq;
						let update_object: TNewsUpdateReq;
						sent_object = {
							news: {
								publish_at: String(new Date().toISOString()),

								link: storedData.seoStore[lang].link,
								title: storedData.seoStore[lang].title,
								tags: storedData.seoStore[lang].tags,
								banner: storedData.seoStore.fileURL,
								btn_url: "",
								color: storedData.seoStore.color,
								description: storedData.seoStore[lang].description,
								news_content: storedData.contentStore[lang],
							},
							language: {
								lang: lang,
							},
						};
						if (variantRequest === "UPDATE") {
							update_object = {
								...sent_object,
								news: {
									...sent_object.news,
									id: get().newsObj.id || -1,
									hidden: get().setting.isHidden,
								},
							};

							return update_object;
						}
						return sent_object;
					},
					setDataFromRequest: ({ dataRu, dataEn }) => {
						let storedData = get().newsObj;

						storedData = {
							seoStore: {
								color: dataRu?.data.color || "",
								fileURL: dataRu?.data.banner || "",
								ru: {
									description: dataRu?.data.description || "",
									tags: dataRu?.data.tags || INIT_SEO_DATA.ru.tags,
									title: dataRu?.data.title || "",
									metaTitle: dataRu?.data.title || "",
									metaDescription: dataRu?.data.description || "",
									link: dataRu?.data.link || "",
								},
								en: {
									description: dataEn?.data.description || "",
									tags: dataEn?.data.tags || INIT_SEO_DATA.en.tags,
									title: dataEn?.data.title || "",
									metaTitle: dataEn?.data.title || "",
									metaDescription: dataEn?.data.description || "",
									link: dataEn?.data.link || "",
								},
							},
							contentStore: {
								ru: dataRu?.data.news_content || INIT_CONSTRUCTOR_DATA.news.ru,
								en: dataEn?.data.news_content || INIT_CONSTRUCTOR_DATA.news.en,
							},
						} as TNewsDataStore;
						set((state) => ({
							...state,
							newsObj: {
								...storedData,
								id: dataRu?.data.id || dataEn?.data.id || -1,
							},
							setting: {
								...get().setting,
								isHidden:
									dataRu?.data.hidden !== undefined ? dataRu.data.hidden : true,
							},
						}));
						get().bindActionData.loadNewsData();
						return storedData;
					},
				},
			};
		},
		{
			name: "news-storage",

			storage: createJSONStorage(() => localStorage),

			partialize: (state) => {
				return { newsObj: state.newsObj, setting: state.setting };
			},
		}
	)
);
