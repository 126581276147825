import { useLanguage } from "@/libs/context/LanguageProvider";
import { FC, useEffect, useState } from "react";
import SettingWidget from "../../_common/AddNewResource/SettingWidget/SettingWidget";
import s from "./WrapperAddNewHistory.module.scss";

import WrapperAddNewResource from "../../_common/_comp/WrapperAddNewResource";

import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import useHistoryStore from "@/store/newResourceStore/history";
import { HeaderActions } from "../../_common/_comp";
import { useManipulationData } from "../../_common/_hooks";
import { TEditingData } from "../../_common/_hooks/use-manipulation-data/type";
import { TVariantOpenPage } from "../../Constructor.type";
import HistoryResource from "./HistoryResource/HistoryResource";
import {
	THistoryCreateReq,
	THistoryUpdateReq,
	TSingleHistoryRes,
} from "@/services/API/history/history.type";
import {
	useCreateHistory,
	useDeleteHistoryById,
	useGetHistoryAllLangById,
	useUpdateHistoryById,
} from "@/services/API/history/hook";
import { Loader } from "@/libs/UI/Jammer";
import { VariantNewResourceContext } from "../../_common/AddNewResource/ReceivingData/_context/VariantNewResourceContext";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";

const WrapperAddNewNews: FC<TVariantOpenPage> = ({
	editFor,
	labelPage,
	id = -1,
}) => {
	const { getLocalization } = useLanguage();
	const {
		historyData,
		id: historyLocalId,
		bindActionData,
		bindTransformData,
	} = useHistoryStore();
	const { bindStore, currentLang } = useConstructorStore();
	const settings = useSettingStore();
	/////////

	const [editingData, setEditingData] = useState<
		TEditingData<TSingleHistoryRes>
	>({
		ru: null,
		en: null,
	});

	const { mutateAsync: createItem } = useCreateHistory();
	const { mutateAsync: deleteItem } = useDeleteHistoryById();
	const { mutateAsync: getAllLangItem } = useGetHistoryAllLangById();
	const { mutateAsync: updateItem } = useUpdateHistoryById();

	/////////

	const { handleSentData, handleLocalRemove } = useManipulationData({
		bindActionData: {
			loadLocalData: bindActionData.loadHistoryData,
			removeLocalData: bindActionData.removeHistoryData,
			updateLocalData: bindActionData.updateHistoryData,
		},
		copyArray: bindStore.history[settings.getCurrentLang("history")],
		editingData,
		variantResource: "history",
		bindTransformData,
		editFor,
	});

	const fetchSentData = async () => {
		const sentSetting = handleSentData();
		if (sentSetting !== null) {
			if (editFor === "ADD") {
				await createItem(sentSetting.sentData as THistoryCreateReq).then(() => {
					sentSetting.clear();
				});
			}
			if (editFor === "UPDATE") {
				let update = sentSetting.sentData as THistoryUpdateReq;

				await updateItem({
					id,
					body: update,
				}).then(() => {
					sentSetting.clear();
				});
			}
		}
	};

	const fetchDeleteNews = async () => {
		const _id = id || historyLocalId || -1;
		await deleteItem({
			lang: settings.getCurrentLang("history"),
			id: _id,
		}).then(() => {
			handleLocalRemove();
		});
	};

	/////////

	useEffect(() => {
		if (id > -1) {
			getAllLangItem({ id }).then((e) => {
				setEditingData({
					en: e.dataEn,
					ru: e.dataRu,
				});
			});
		}
	}, [id]);

	if (editFor === "UPDATE" && !editingData.ru && !editingData.en)
		return (
			<Loader
				params={{
					visible: !editingData.ru && !editingData.en,
				}}
			/>
		);
	return (
		<VariantNewResourceContext.Provider value={{ variantResource: "history" }}>
			<WrapperAddNewResource
				title={getLocalization(labelPage)}
				goBack={true}
				rightComp={
					<HeaderActions
						disabled={false}
						handleUpdateStore={bindActionData.updateHistoryData}
					/>
				}
			>
				<div className={s.wrap}>
					<div className={s.main_block}>
						<HistoryResource />
					</div>
					<div className={s.widgets_block}>
						<SettingWidget
							handleRemove={fetchDeleteNews}
							handleSent={fetchSentData}
							editFor={editFor}
						/>
					</div>
				</div>
			</WrapperAddNewResource>
		</VariantNewResourceContext.Provider>
	);
};
export default WrapperAddNewNews;
