import { ColorPicker } from "@/features/Constructor/_common/_comp";
import s from "./HistoryResource.module.scss";

import UploadImage from "@/features/Constructor/_common/_comp/UploadImage/UploadImage";
import { Input } from "@/libs/UI";
import { useSeoStore } from "@/store/newResourceStore/history/seo/seo";
import { useConstructorStore } from "../../../../../store/newResourceStore/_common/constructor/constructorStore";
import { useSettingStore } from "@/store/newResourceStore/_common/setting/settingStore";

const HistoryResource = () => {
	const { seoData, setSeoData } = useSeoStore();
	const settings = useSettingStore();
	return (
		<div className={s.seo}>
			<div className={s.uploads}>
				<div className={s.inputFile_block}>
					<p>Превью</p>
					<div className={s.inputFile}>
						<UploadImage
							fileURL={seoData.preview_image}
							onChange={(e) => {
								setSeoData({ field: "preview_image", value: e });
							}}
							idInput={"preview"}
						/>
					</div>
				</div>
				{settings.getCurrentLang("history") === "ru" && (
					<div className={s.inputFile_block}>
						<p>История RUS</p>
						<div className={s.inputFile}>
							<UploadImage
								fileURL={seoData.ru.image}
								onChange={(e) =>
									setSeoData({ field: "image", value: e, lang: "ru" })
								}
								idInput={"historyRus"}
							/>
						</div>
					</div>
				)}
				{settings.getCurrentLang("history") === "en" && (
					<div className={s.inputFile_block}>
						<p>История ENG</p>
						<div className={s.inputFile}>
							<UploadImage
								fileURL={seoData.en.image}
								onChange={(e) =>
									setSeoData({ field: "image", value: e, lang: "en" })
								}
								idInput={"historyEng"}
							/>
						</div>
					</div>
				)}
			</div>

			<div className={s.inputs_block}>
				{settings.getCurrentLang("history") === "ru" && (
					<Input
						placeholder="Названия RUS"
						value={seoData.ru.name}
						onChange={(e) =>
							setSeoData({ field: "name", value: e.target.value, lang: "ru" })
						}
					/>
				)}
				{settings.getCurrentLang("history") === "en" && (
					<Input
						placeholder="Названия ENG"
						value={seoData.en.name}
						onChange={(e) =>
							setSeoData({ field: "name", value: e.target.value, lang: "en" })
						}
					/>
				)}

				<div className={s.inputs}>
					<div className={s.inputs_promo}>
						<Input
							placeholder="Промокод"
							value={seoData.promocode}
							onChange={(e) =>
								setSeoData({ field: "promocode", value: e.target.value })
							}
						/>
					</div>
					<div className={s.inputs_link}>
						<Input
							placeholder="Ссылка на казино"
							value={seoData.link}
							onChange={(e) =>
								setSeoData({ field: "link", value: e.target.value })
							}
						/>
					</div>
				</div>
			</div>
			<div className={s.colorPicker}>
				<ColorPicker
					activeColor={seoData.color}
					onChange={(color) => {
						setSeoData({ field: "color", value: color });
					}}
					label="Цвет истории"
				/>
			</div>
		</div>
	);
};

export default HistoryResource;
