import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	createCasinoById,
	deleteCasinoById,
	getCasinoById,
	getTopCasino,
	updateCasinoById,
} from "./casino";
import { TLangKey } from "@/libs/context/LanguageProvider";
import {
	TCasinoCreateReq,
	TCasinoDeleteRes,
	TParamsCasinoListReq,
	TParamsCasinoUpdateReq,
	TParamsSingleCasinoAllLangReq,
	TParamsSingleCasinoReq,
} from "./casino.type";
import { toast } from "react-toastify";
import { toastGetAllLang } from "@/libs/utils/toastGetAllLang";

export const useGetTopCasino = (params: TParamsCasinoListReq) => {
	return useQuery(["topCasino", params.lang], () => getTopCasino(params), {
		onSuccess: (data) => {},
		onError: (error) => {
			console.log("get all casino", error);
		},
	});
};

export const useGetCasinoById = ({ lang, id }: TParamsSingleCasinoReq) => {
	return useQuery(["casino", id, lang], () => getCasinoById({ lang, id }), {
		onSuccess: (data) => {},
		onError: (error) => {
			console.log("get casino by id", error);
		},
	});
};
export const useGetCasinoAllLangById = () => {
	return useMutation(
		async ({ id }: TParamsSingleCasinoAllLangReq) => {
			const [dataRu, dataEn] = await Promise.allSettled([
				getCasinoById({ lang: "ru", id }),
				getCasinoById({ lang: "en", id }),
			]);
			toastGetAllLang({
				en: dataEn.status === "fulfilled",
				ru: dataRu.status === "fulfilled",
			});
			return {
				dataRu: dataRu.status === "fulfilled" ? dataRu.value : null,
				dataEn: dataEn.status === "fulfilled" ? dataEn.value : null,
				errors: {
					ru: dataRu.status === "rejected" ? dataRu.reason : null,
					en: dataEn.status === "rejected" ? dataEn.reason : null,
				},
			};
		},
		{
			onSuccess: (data) => {},
			onError: (error) => {
				console.log("get all langs casino", error);
			},
		}
	);
};

export const useCreateCasino = () => {
	return useMutation(
		async ({ casino, language }: TCasinoCreateReq) => {
			return await createCasinoById({ casino, language });
		},
		{
			onSuccess: (data) => {
				toast.success("Казино добавлено");
			},
			onError: (error) => {
				toast.error("Ошибка добавления");
				console.log("create casino", error);
			},
		}
	);
};

export const useUpdateCasinoById = () => {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ id, body }: TParamsCasinoUpdateReq) => {
			return await updateCasinoById({ id, body });
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("topCasino");
				toast.success("Казино обновлено");
			},
			onError: (error) => {
				toast.error("Ошибка обновления");
				console.log("update casino", error);
			},
		}
	);
};

export const useDeleteCasinoById = () => {
	return useMutation(
		async ({ lang, id }: TCasinoDeleteRes) => {
			return await deleteCasinoById({ lang, id });
		},
		{
			onSuccess: (data) => {
				toast.success("Казино удалено");
			},
			onError: (error) => {
				toast.error("Ошибка удаления");
				console.log("delete casino", error);
			},
		}
	);
};
