import { TextArea } from "@/libs/UI";
import { Button } from "@/libs/UI/CustomTags";
import { useLanguage } from "@/libs/context/LanguageProvider";
import { useInput } from "@/libs/hooks/use-input";
import { FC, useContext, useEffect, useRef } from "react";
import s from "./InsertJson.module.scss";
import { useConstructorStore } from "@/store/newResourceStore/_common/constructor/constructorStore";
import { VariantNewResourceContext } from "../_context/VariantNewResourceContext";
import { TVariantAddedContent } from "../ReceivingData.type";
type TProps = {
	handleChangeVariantEditor: (params: TVariantAddedContent) => void;
};
const InsertJson: FC<TProps> = ({ handleChangeVariantEditor }) => {
	const { getLocalization } = useLanguage();
	const { onChangeText, value } = useInput();
	const { variantResource } = useContext(VariantNewResourceContext);
	const { bindActionSection } = useConstructorStore();
	let textareaRef = useRef<HTMLTextAreaElement>(null);
	//
	const handleInputChange = (e: string) => {
		onChangeText(e);
		if (textareaRef.current) {
			textareaRef.current.style.height = "auto";
			textareaRef.current.style.height =
				textareaRef.current.scrollHeight < 450
					? `${textareaRef.current.scrollHeight}px`
					: "450px";
		}
	};
	const handleInsertJSON = () => {
		if (value) {
			bindActionSection.setStore({
				key: variantResource,
				content: JSON.parse(value),
			});
			handleChangeVariantEditor("constructor");
		}
	};
	//
	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, []);

	return (
		<div className={s.wrap}>
			<TextArea
				type="insert"
				value={value}
				onChange={handleInputChange}
				placeholder={getLocalization("Код JSON")}
				ref={textareaRef}
			/>

			<Button
				type="primary"
				disabled={value.length < 0}
				onClick={() => {
					handleInsertJSON();
					// bindActionSection.setStore({
					// 	key: variantResource,
					// 	content: JSON.parse(value),
					// });
				}}
			>
				{getLocalization("Применить")}
			</Button>
		</div>
	);
};
export default InsertJson;
