import { FC } from "react";
import { TStatusSelector, TStatusSelectorKey } from "../type";
import ItemStatus from "./ItemStatus/ItemStatus";
import { SelectorHOC } from "@/libs/HOC";
import s from "./StatusFilter.module.scss";
import { P } from "@/libs/UI/CustomTags";
import { useLanguage } from "@/libs/context/LanguageProvider";
type TProps = {
	dataSelector: TStatusSelector[];
	updateDataSelector: (key: TStatusSelectorKey) => void;
};
const StatusFilter: FC<TProps> = ({ dataSelector, updateDataSelector }) => {
	const { getLocalization } = useLanguage();

	return (
		<div className={s.wrap}>
			<P size="m">{getLocalization("Статус")}</P>
			<div>
				<SelectorHOC background="var(--casino-bg-blue-grey)">
					<ItemStatus {...{ dataSelector, updateDataSelector }} />
				</SelectorHOC>
			</div>
		</div>
	);
};
export default StatusFilter;
